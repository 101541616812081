

.reservation-container {
	position: relative;
	top: 50px;
  }
  .mateza-container p {
	color: rgb(255, 255, 255);
	margin-right: 10px;
	margin-bottom: 20px;
  }
  
  .mateza-link {
	text-decoration: none;
	color: rgb(255, 255, 255);
  }
  
  @media (max-width: 992px) {
	.mateza-container {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  margin-top: 70px;
	}
  }
  
  @media (min-width: 992px) {
	.mateza-container {
	  position: fixed;
	  bottom: 20px;
	  left: 50vw;
	  transform: translateX(-50%);
	}
  }

  .fullscreen-bg-reservation {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -100;
	background-image: url('/src/assets/bg3.webp');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
  }

  .frame {
	background-color: rgba(255, 255, 255, 0.654);
	padding: 5px;
	border-radius: 10px;
	height: calc(max(80vw, 700px));
	width: 1000px;
	z-index: -10;
	backdrop-filter: blur(10px); /* Blur effect */
	transform: translateX(-29px) translateY(-20px);
	position: absolute;
  }


  @media (max-width: 575.98px) {
	.frame {
		top: 0px;
		right: 0px;
		left: 0px;
		bottom: 0;
		position: fixed;
		height: auto;
		width: auto;
		z-index: 100px;;
		border-radius: 0px;
		transform: none;
	}

  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
	.frame {
		top: 0px;
		right: 0px;
		left: 0px;
		bottom: 0;
		position: fixed;
		height: auto;
		width: auto;
		z-index: 100px;;
		border-radius: 0px;
		transform: none;
	}
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
	.frame {
		top: 0px;
		right: 0px;
		left: 0px;
		bottom: 0;
		position: fixed;
		height: auto;
		width: auto;
		z-index: 100px;;
		border-radius: 0px;
		transform: none;
	}
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
	.frame {
		top: 0px;
		left: 10px;
		height: 650px;
		width: 1000px;
	}
  }
  
  @media (min-width: 1199.98px) and (max-width: 1400px) {
	.frame {
		top: 0px;
		left: 200px;
		height: 570px;
		width: 800px;
	}
  }

  @media (min-width: 1400px) {
	.frame {
		top: 0px;
		left: 240px;
		height: 600px;
		width: 900px;
	}
  }

  .help-icon {
	position: fixed;
	top: 20px;
	right: 20px;
	background-color: #ffffff;
	color: rgb(0, 0, 0);
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	cursor: pointer;
	z-index: 1000;
  }

  @media (max-width: 768px) {
	.help-icon {
		display: none;
	  }
	}

  @media (max-width: 992px) {
	  .mateza-container p {
		color: black
	  }

	  .mateza-link {
		color: black;
		opacity: 0.6;
	  }
  }

  .language-selector-col {
	display: flex;
	justify-content: flex-end;
  }
  
  .language-selector {
	width: 90px; /* Adjust the width as needed */
	margin-left: auto;
  }
  

  .title-card {
	background: white;
	border: none;
	border-radius: 15px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 1rem;
  }
  
  .language-selector .language-selector {
	width: 100%;
  }
  
  .giftcard-card {
	background: white;
	border: none;
	border-radius: 15px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s;
  }
  
  .giftcard-card:hover {
	transform: translateY(-10px);
  }
  
  .giftcard-card .card-body {
	padding: 2rem;
  }
  
  .giftcard-card .card-title {
	font-size: 1.5rem;
	margin-bottom: 1rem;
  }
  
  .giftcard-card .card-text {
	font-size: 0.95rem;
	color: #555;
  }

  .choose-button {
	padding: 5px 20px !important;
	border-radius: 40px;
  }
  

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Pacifico&display=swap');



h1 {
  font-family: 'Pacifico', cursive;
}
